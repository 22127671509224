import "./src/styles/global.scss";
import packageInfo from "./package.json";

if (typeof window !== "undefined") {
  window.claypoole = window.claypoole || {};
  window.claypoole.version = packageInfo.version;
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  if (routerProps.location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }
  return false;
};
