import React, { useRef, useState, useContext, useEffect } from "react";
import { Link } from "../links";
import whiteLogo from "../../images/csLogo.svg";
import classNames from "classnames";
import ImageLoader from "../../lib/image-loader";
import {
  navLinks1,
  navLinks2,
  menuNavLinks,
} from "../../data/components/navbar";
import { useLocation } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import "./header.scss";
import ActiveSectionContext from "../../context/activeSectionCtx";
import { useCheckScreenSize } from "../../lib/checkScreenSize";
import CityScape from "../animations/cityscape";

const Header = React.forwardRef((props) => {
  const location = useLocation();
  const { pathname } = location;
  const [linksMenuOpen, setLinksMenuOpen] = useState(false);
  const [isHoveringLink, setIsHoveringLink] = useState(null);
  const transitionImageRef = useRef(null);
  const linksRef = useRef(null);
  const reflectionRef = useRef(null);
  const { menuActive, setMenuActive } = useContext(ActiveSectionContext);
  const isTablet = useCheckScreenSize(1100);

  const linksClasses = classNames("cs-header__links", {
    "cs-header__links--open": linksMenuOpen,
  });

  useEffect(() => {
    const tl1 = gsap.timeline();
    const tl2 = gsap.timeline();
    if (menuActive) {
      setLinksMenuOpen(true);
      gsap.fromTo(
        linksRef.current,
        {
          top: "100vh",
          y: 88,
          height: 0,
        },
        {
          top: 0,
          height: "100vh",
          duration: 0.75,
          delay: 0.2,
          ease: "power1.out",
        }
      );
      gsap.fromTo(
        reflectionRef.current,
        {
          height: 0,
          top: "100%",
        },
        {
          top: "46.7%",
          height: "auto",
          duration: 0.75,
          delay: 0.2,
          ease: "power1.out",
        }
      );
      tl1.fromTo(
        transitionImageRef.current,
        {
          top: "100vh",
          y: 88,
          height: 0,
        },
        {
          top: 0,
          height: "100vh",
          duration: 0.5,
          delay: 0,
          ease: "power1.out",
        }
      );
      tl1.to(transitionImageRef.current, {
        y: 0,
        height: 0,
      });
    } else {
      tl2.fromTo(
        transitionImageRef.current,
        {
          top: "100vh",
          y: 88,
          height: 0,
        },
        {
          top: 0,
          height: "100vh",
          duration: 0.5,
          delay: 0,
          ease: "power1.out",
        }
      );
      tl2.to(transitionImageRef.current, {
        y: 0,
        height: 0,
        duration: 0.5,
        onComplete: () => setLinksMenuOpen(false),
      });
      gsap.to(linksRef.current, {
        duration: 0.75,
        y: 0,
        height: "auto",
      });
    }
  }, [menuActive]);

  return (
    <header ref={props.forwardRef} className="cs-header">
      <div
        ref={transitionImageRef}
        className="cs-header__transition-image cursor-pointer"
      >
        {linksMenuOpen && (
          <StaticImage
            src="../../images/transition.jpg"
            alt="transition image"
            // layout="fullWidth"
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        )}
      </div>
      <div className="cs-header__inner">
        <div ref={linksRef} className={linksClasses}>
          {!linksMenuOpen && (
            <>
              {navLinks1.map((item) => {
                const { title, url } = item;
                const navLinkClasses = classNames("cs-header__nav-link", {
                  "cs-header__nav-link--active":
                    pathname === url && !linksMenuOpen,
                  "cs-header__nav-link--open": linksMenuOpen,
                });
                return (
                  <div
                    key={title}
                    onClick={() => {
                      if (menuActive) {
                        setMenuActive(false);
                      }
                    }}
                    className={navLinkClasses}
                  >
                    <Link to={url}>{title}</Link>
                  </div>
                );
              })}
              {
                <div className="cs-header__logo">
                  <Link to="/">
                    <ImageLoader
                      src={whiteLogo}
                      alt="Claypoole-Logo"
                      title="Claypoole Search"
                      aspectRatio="184 / 47"
                    />
                  </Link>
                </div>
              }
              {navLinks2.map((item) => {
                const { title, url } = item;
                const navLinkClasses = classNames("cs-header__nav-link", {
                  "cs-header__nav-link--active": pathname === url,
                });
                return (
                  <div
                    key={title}
                    onClick={() => {
                      if (menuActive) {
                        setMenuActive(false);
                      }
                    }}
                    className={navLinkClasses}
                  >
                    <Link to={url}>{title}</Link>
                  </div>
                );
              })}
            </>
          )}
          {menuActive && (
            <>
              <div className="cs-header__menu">
                {menuNavLinks.map((item) => {
                  const { title, url, whichCityscape } = item;
                  const navLinkClasses = classNames(
                    "cs-header__menu__link cursor-pointer",
                    {
                      "cs-header__menu__link--active": pathname === url,
                    }
                  );
                  return (
                    <>
                      <div
                        key={title}
                        onClick={() => {
                          if (menuActive) {
                            setMenuActive(false);
                          }
                        }}
                        className={navLinkClasses}
                      >
                        <Link className="cursor-pointer" to={url}>
                          {title}
                        </Link>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="cs-header__socials">
                <div className="cursor-pointer">
                  <Link
                    isOutbound
                    to="https://www.linkedin.com/company/claypoole-search/"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="var(--blue)"
                        d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="cs-header__socials__author cursor-pointer">
                  <Link isOutbound to="https://www.danielrbogart.com/">
                    Code by Daniel
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
});

export default Header;
