import React, { useRef, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./image-loader.scss";
import { useCheckScreenSize } from "./checkScreenSize";

const ImageLoader = ({ src, alt, aspectRatio = "1 / 1", ...props }) => {
  const imageRef = useRef(null);

  const isMobile = useCheckScreenSize(768);

  const aspectRatioClass = isMobile ? "aspect-ratio--mobile" : "";

  const whichImage = isMobile ? (
    <img alt={alt} src={src} />
  ) : (
    <LazyLoadImage
      alt={alt}
      effect="blur"
      src={src}
      threshold={400}
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
    />
  );

  return (
    <div
      style={{
        "--aspect-ratio": aspectRatio,
        width: "100%",
        position: "relative",
      }}
      className={`image-loader ${aspectRatioClass}`}
      ref={imageRef}
      {...props}
    >
      {whichImage}
    </div>
  );
};

export default ImageLoader;
