export const footerLinks = [
  {
    title: "Philanthropy",
    link: "/philanthropy",
  },
  {
    title: "Compensation Survey",
    link: "/compensation-survey",
  },
  {
    title: "Affiliates",
    link: "/locations-and-affiliates",
  },
  {
    title: "Newsroom",
    link: "/newsroom",
  },
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Locations",
    link: "/locations-and-affiliates",
  },
];
