import React, { useEffect, useState, useRef, useContext } from "react";
import gsap from "gsap";
import "./global-cursor.scss";
import ActiveSectionContext from "../../context/activeSectionCtx";

const GlobalCursor = ({ pathname }) => {
  const cursorRef = useRef(null);
  const dotRef = useRef(null);
  const [isCursorPointing, setIsCursorPointing] = useState(false);
  const [hasMouseMoved, setHasMouseMoved] = useState(false);
  const { isDesktop, menuActive } = useContext(ActiveSectionContext);

  useEffect(() => {
    const cursor = cursorRef.current;

    gsap.set(cursor, {
      xPercent: -52,
      yPercent: -55,
      opacity: 1,
    });

    const handleMouseMove = (e) => {
      if (!hasMouseMoved) {
        setHasMouseMoved(true);
      }
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.75,
        ease: "elastic.out(1, 0.3)",
      });
    };

    const handleMouseDown = () => {
      gsap.killTweensOf(cursor);
      const newScaleValue = isCursorPointing ? 2.6 : 1.4;
      gsap.to(cursor, {
        scale: newScaleValue,
        duration: 0.2,
        ease: "power2.out",
      });
    };

    const handleMouseUp = () => {
      gsap.killTweensOf(cursor);
      const scaleValue = isCursorPointing ? 2 : 1;
      gsap.to(cursor, {
        scale: scaleValue,
        duration: 0.2,
        ease: "power2.out",
      });
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [hasMouseMoved, isCursorPointing, pathname, menuActive]);

  useEffect(() => {
    const handleMouseOver = () => {
      const cursor = cursorRef.current;
      gsap.to(cursor, {
        scale: 2,
        duration: 0.3,
        ease: "power2.out",
        backgroundColor: "orange",
        opacity: "66.666%",
      });
      setIsCursorPointing(true);
    };

    const handleMouseOut = () => {
      const cursor = cursorRef.current;
      gsap.to(cursor, {
        scale: 1,
        duration: 0.3,
        ease: "power2.out",
        backgroundColor: "transparent",
        opacity: 1,
      });
      setIsCursorPointing(false);
    };

    const pointerElements = document.querySelectorAll(".cursor-pointer");

    pointerElements.forEach((element) => {
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
    });

    return () => {
      pointerElements.forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
    };
  }, [pathname, hasMouseMoved, menuActive]);

  return (
    <>
      {isDesktop && (
        <div ref={cursorRef} className="cs-global-cursor">
          <span className="cs-global-cursor--dot" ref={dotRef}></span>
        </div>
      )}
    </>
  );
};

export default GlobalCursor;
