export const navLinks1 = [
  {
    title: "Compensation Survey",
    url: "/compensation-survey",
  },
  {
    title: "Philanthropy",
    url: "/philanthropy",
  },
];

export const navLinks2 = [
  {
    title: "Locations and Affiliates",
    url: "/locations-and-affiliates",
  },
  {
    title: "Newsroom",
    url: "/newsroom",
  },
];

export const menuNavLinks = [
  {
    title: "Compensation Survey",
    url: "/compensation-survey",
    whichCityscape: "cityscape1",
  },
  {
    title: "Philanthropy",
    url: "/philanthropy",
    whichCityscape: "cityscape2",
  },
  {
    title: "Locations and Affiliates",
    url: "/locations-and-affiliates",
    whichCityscape: "cityscape3",
  },
  {
    title: "Newsroom",
    url: "/newsroom",
    whichCityscape: "cityscape4",
  },
];
