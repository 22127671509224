export const seoDataObj = {
  "": {
    description: "Claypoole Search",
    title:
      "Claypoole Search recruits legal and compliance across international financial hubs, like London, Dublin, Singapore, and Hong Kong via a network of specialized affiliates who share our values and approach.",
    // image: "",
  },
  "/locations-and-affiliates": {
    description:
      "Claypoole Search recruits legal and compliance across international financial hubs, like London, Dublin, Singapore, and Hong Kong via a network of specialized affiliates who share our values and approach.",
    title: "Locations and Affiliates",
    // image: "",
  },
  "/philanthropy": {
    description:
      "Claypoole Search identifies and partners with nonprofits who make a tangible difference in their communities by donating 5% of our yearly net profits.",
    title: "Philanthropy",
    // image: "",
  },
  "/newsroom": {
    description: "Newsroom",
    title:
      "David Claypoole has been recognized as the leading authority on compensation for in-house legal and compliance talent with multiple published interviews, a role as an expert witness, as well as many speaking engagements at industry conferences.",
    // image: "",
  },
  "/compensation-survey": {
    description:
      "Claypoole Search offers the most up-to-date compensation survey for in-house legal and compliance talent.",
    title: "Compensation Survey",
    // image: "",
  },
};
