import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const DynamicLink = ({
  ariaLabel = "",
  to,
  label,
  className = "",
  children = [],
  isOutbound,
  onClick = () => {},
  page = "",
  section = "",
  subsection = "",
  target = null,
  contains = "",
  isCTA = false,
  isAnchor = false,
  download = false,
  ...rest
}) => {
  // regex check for lack of http or http with 'claypoolesearch.com'
  const isOut =
    typeof isOutbound === "boolean"
      ? isOutbound
      : /^http.+$|^mailto/.test(to) && !/www\.claypoolesearch\.com/.test(to);

  const _ariaLabel =
    typeof children === "string" && !ariaLabel ? children : ariaLabel;
  if (!isOut && !target && !isAnchor) {
    return (
      <Link
        aria-label={_ariaLabel}
        to={to}
        className={className}
        download={download}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Link>
    );
  } else if (isAnchor) {
    // eslint-disable-line
    return (
      <AnchorLink
        aria-label={_ariaLabel}
        to={to}
        className={className}
        onClick={onClick}
      >
        {children}
      </AnchorLink>
    );
  }

  const linkClasses = classNames("outboundlink", className);

  return (
    <a
      aria-label={_ariaLabel}
      href={to}
      target={isOut && !target ? "_blank" : target}
      className={linkClasses}
      rel="noreferrer noopener"
      download={download}
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  );
};

DynamicLink.propTypes = {
  ariaLabel: PropTypes.string,
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  isOutbound: PropTypes.bool,
  onClick: PropTypes.func,
  page: PropTypes.string,
  section: PropTypes.string,
  subsection: PropTypes.string,
  target: PropTypes.string,
  contains: PropTypes.string,
  isCTA: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isAnchor: PropTypes.bool,
  children: PropTypes.any,
};

export default DynamicLink;
