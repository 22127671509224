import React, { useEffect, useRef } from "react";
import "./cityscape.scss";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
gsap.registerPlugin(DrawSVGPlugin);

const cityscape1 = (
  <svg
    width="1918"
    height="774"
    viewBox="0 0 1918 774"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1015.67 671.986H1064.62V371.611H1089.1M1015.67 671.986V487.227V207V59.5M1015.67 671.986V133.25H0M1335.65 89.3724H1305.83V182.319H1285.81V567.705V340H1231.51V283.451H1113.58V518.965V371.611H1089.1M1335.65 89.3724V0M1335.65 89.3724H1366.8V182.319H1388.16V722.993H1440.23V774V230.5H1508.77V671.986H1559.06V756.998V487.227H1595.1V518.965V283.451H1621.36M1621.36 283.451V182.319M1621.36 283.451H1648.51V447.555H1707.7V567.705V413.55H1740.18M1740.18 413.55V351M1740.18 413.55H1775.79V630.047M1775.79 756.998V630.047M1775.79 630.047H1829.19M1829.19 630.047H1843.43V756.998H1915V487.227H1829.19V630.047ZM1015.67 59.5H1052.39M1015.67 59.5H1089.1M1089.1 59.5V371.611M1089.1 59.5H1052.39M1052.39 59.5V9.5"
      stroke="#1F3A93"
      stroke-width="6"
    />
  </svg>
);

const cityscape2 = (
  <svg
    width="1915"
    height="774"
    viewBox="0 0 1915 774"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1012.67 671.986H1061.62V371.611H1086.1M1012.67 671.986V487.227V182.319M1012.67 671.986V274.5H0M1332.65 89.3724H1302.83V182.319H1282.81V567.705V340H1228.51V283.451H1110.58V518.965V371.611H1086.1M1332.65 89.3724V0M1332.65 89.3724H1363.8V182.319H1385.16V722.993H1437.23V774V230.5H1505.77V671.986H1556.06V756.998V487.227H1592.1V518.965V283.451H1618.36M1618.36 283.451V182.319M1618.36 283.451H1645.51V447.555H1704.7V567.705V413.55H1737.18M1737.18 413.55V351M1737.18 413.55H1772.79V630.047M1772.79 756.998V630.047M1772.79 630.047H1826.19M1826.19 630.047H1840.43V756.998H1912V487.227H1826.19V630.047ZM1012.67 182.319H1049.39M1012.67 182.319H1086.1M1086.1 182.319V371.611M1086.1 182.319H1049.39M1049.39 182.319V112"
      stroke="#1F3A93"
      stroke-width="6"
    />
  </svg>
);
const cityscape3 = (
  <svg
    width="1914"
    height="774"
    viewBox="0 0 1914 774"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1011.17 671.986H1060.12V371.611H1084.6M1011.17 671.986V487.227V211.789M1011.17 671.986V413.55H0M1331.15 89.3724H1301.33V182.319H1281.31V567.705V340H1227.01V283.451H1109.08V518.965V371.611H1084.6M1331.15 89.3724V0M1331.15 89.3724H1362.3V182.319H1383.66V722.993H1435.73V774V230.5H1504.27V671.986H1554.56V756.998V487.227H1590.6V518.965V283.451H1616.86M1616.86 283.451V182.319M1616.86 283.451H1644.01V447.555H1703.2V567.705V413.55H1735.68M1735.68 413.55V351M1735.68 413.55H1771.29V630.047M1771.29 756.998V630.047M1771.29 630.047H1824.69M1824.69 630.047H1838.93V756.998H1910.5V487.227H1824.69V630.047ZM1011.17 211.789H1047.89M1011.17 211.789H1084.6M1084.6 211.789V371.611M1084.6 211.789H1047.89M1047.89 211.789V133"
      stroke="#1F3A93"
      stroke-width="6"
    />
  </svg>
);

const cityscape4 = (
  <svg
    width="1919"
    height="774"
    viewBox="0 0 1919 774"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1016.17 671.986H1065.12V371.611H1089.6M1016.17 671.986V487.227V283.451M1016.17 671.986V555.5H0M1336.15 89.3724H1306.33V182.319H1286.31V567.705V300.201H1232.01V211.789H1114.08V518.965V371.611H1089.6M1336.15 89.3724V0M1336.15 89.3724H1367.3V182.319H1388.66V722.993H1440.73V774V230.5H1509.27V671.986H1559.56V756.998V487.227H1595.6V518.965V283.451H1621.86M1621.86 283.451V182.319M1621.86 283.451H1649.01V447.555H1708.2V567.705V413.55H1740.68M1740.68 413.55V351M1740.68 413.55H1776.29V630.047M1776.29 756.998V630.047M1776.29 630.047H1829.69M1829.69 630.047H1843.93V756.998H1915.5V487.227H1829.69V630.047ZM1016.17 283.451H1052.89M1016.17 283.451H1089.6M1089.6 283.451V371.611M1089.6 283.451H1052.89M1052.89 283.451V220.5"
      stroke="#1F3A93"
      stroke-width="6"
    />
  </svg>
);

const CityScape = ({ whichCityscape, isHoveringLink }) => {
  const isTablet = useCheckScreenSize(1100);
  const cityscapeRef = useRef(null);
  useEffect(() => {
    let animation;
    if (isTablet) {
      return () => {
        if (animation) animation.kill();
      };
    }
    const path = cityscapeRef.current.querySelector("path");

    // Set initial state to invisible
    gsap.set(path, { drawSVG: "0% 0%" });

    if (isHoveringLink === whichCityscape) {
      animation = gsap.to(path, {
        duration: 6,
        drawSVG: "0% 100%",
        ease: "power1.out",
        delay: 0,
      });
    } else {
      animation = gsap.to(path, {
        duration: 6,
        drawSVG: "0% 0%",
        ease: "power1.out",
      });
    }

    // Cleanup function
    return () => {
      if (animation) animation.kill();
    };
  }, [isHoveringLink, whichCityscape]);


  const cityscapeMap = {
    cityscape1: cityscape1,
    cityscape2: cityscape2,
    cityscape3: cityscape3,
    cityscape4: cityscape4,
  };
  return (
    <div ref={cityscapeRef} className="cs-cityscape">
      <span></span>
      {cityscapeMap[whichCityscape]}
    </div>
  );
};

export default CityScape;
