import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import { Header, Footer } from "../components/global";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ActiveSectionContext from "../context/activeSectionCtx";
import { Link } from "../components/links";
import classNames from "classnames";
import ImageLoader from "../lib/image-loader";
import csLogo from "../images/csLogo.svg";
import { useCheckScreenSize } from "../lib/checkScreenSize";
import useIsDesktop from "../lib/useIsDesktop";
import GlobalCursor from "../components/global-cursor";
gsap.registerPlugin(ScrollTrigger);

const LandingPageLayout = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const [activeSection, setActiveSection] = useState(null);
  const [shouldExecute, setShouldExecute] = useState(true);
  const [menuActive, setMenuActive] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const isTablet = useCheckScreenSize(1200);
  const isMobile = useCheckScreenSize(600);
  const isDesktop = useIsDesktop();

  const headerRef = useRef(null);
  const floatingButtonRef = useRef(null);
  const floatingLogoRef = useRef(null);
  const { pathname } = location;

  useEffect(() => {
    if (isTablet) {
      gsap.set(headerRef.current, {
        y: -headerRef.current.offsetHeight,
      });
      setShowFloatingButton(true);
      return;
    }

    if (isMobile) {
      gsap.set(floatingLogoClasses.current, {
        y: -100,
      })
    }

    if (headerRef.current && !isTablet) {
      ScrollTrigger.create({
        start: "top+=150 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onEnter: () => {
          gsap.to(headerRef.current, {
            y: -headerRef.current.offsetHeight,
            duration: 0.1,
            delay: 0.25,
            ease: "power1.inOut",
            onComplete: () => setShowFloatingButton(true),
          });
        },
      });

      ScrollTrigger.create({
        start: "top+=100 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onLeaveBack: () => {
          gsap.to(headerRef.current, {
            y: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
              setShowFloatingButton(false);
              setMenuActive(false);
            },
          });
        },
      });
    }
  }, [setShowFloatingButton, setMenuActive, isTablet, isMobile]);


    useEffect(() => {
      const lenis = new Lenis({
        lerp: 0.1,
        duration: 1.2,
        wheelMultiplier: 1,
        touchMultiplier: 1,
        smoothWheel: true,
      });
      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
      const rafId = requestAnimationFrame(raf);
      return () => {
        lenis.off("scroll");
        cancelAnimationFrame(rafId);
      };
    }, []);

  const seoData = seoDataObj[pathname] ||
    seoDataObj[pathname.substr(0, pathname.length - 1)] || {
      title,
      description,
      meta,
    };

    const hamburgerClassses = classNames("floating-nav__button", {
      "floating-nav__button--active": menuActive,
    });
    const floatingNavClasses = classNames("floating-nav cursor-pointer", {
      "floating-nav--active": showFloatingButton && !menuActive,
      "floating-nav--active--open": showFloatingButton && menuActive,
    });
    const floatingLogoClasses = classNames("cs-floating-logo cursor-pointer", {
      "cs-floating-logo--active": showFloatingButton,
      "cs-floating-logo--open": showFloatingButton && menuActive,
      "cs-floating-logo--hidden": isMobile,
    });

  return (
    <ActiveSectionContext.Provider
      value={{
        activeSection,
        setActiveSection,
        shouldExecute,
        setShouldExecute,
        menuActive,
        setMenuActive,
        isDesktop,
      }}
    >
      <div className="cs-site">
        {seoData && <SEO {...seoData} />}
        <Header forwardRef={headerRef} location={location} />
        <Link
          onClick={() => setMenuActive(false)}
          to="/"
          className={floatingLogoClasses}
        >
          <ImageLoader
            src={csLogo}
            alt="Claypoole-Logo"
            title="Claypoole Search"
            aspectRatio="184 / 47"
          />
        </Link>
        <div
          ref={floatingButtonRef}
          className={floatingNavClasses}
          onClick={() => setMenuActive(!menuActive)}
        >
          <button
            onClick={() => setMenuActive(!menuActive)}
            className={hamburgerClassses}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="cs-content">{children}</div>
        <Footer />
      </div>
      <GlobalCursor pathname={pathname} />
    </ActiveSectionContext.Provider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withLocation(LandingPageLayout);
