import React from "react";
import { Link } from "../links";
import { footerLinks } from "../../data/components/footer";
import linkedin2 from "../../images/common/linkedin2.svg";
import { StaticImage } from "gatsby-plugin-image";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="cs-footer">
      <div className="cs-footer__inner">
        <div className="cs-footer__logo">
          <StaticImage width={184} src="../../images/logo.png" alt="Claypoole Search Logo" />
        </div>
        <p className="cs-footer__meta-description">
          Founded by David Claypoole, Claypoole Search (formerly Parks Legal
          Placement) is the premier executive search firm recruiting lawyers and
          compliance professionals for registered investment advisors and other
          alternative asset managers – including private equity, venture, hedge
          funds, and family offices.
        </p>
        <div className="cs-footer__links">
          {footerLinks.map((item) => {
            const { title, link } = item;
            return (
              <div key={title} className="cs-footer__links__link">
                <Link to={link}>{title}</Link>
              </div>
            );
          })}
        </div>
        <div className="cs-footer__social">
          <div className="cs-footer__social__inner">
            <p>Follow us</p>
            <div className="cs-footer__social__link cursor-pointer">
              <Link
                isOutbound
                to="https://www.linkedin.com/company/claypoole-search/about/"
              >
                <img src={linkedin2} alt="linkedin-icon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="cs-footer__lower">
          <p>2023 © Claypoole Search. All Rights Reserved.</p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
