import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./button.scss";

const HTMLButton = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);

const Button = ({
  animateIconOnHover = true,
  children,
  className = "",
  component,
  hollow = false,
  loading = false,
  normalizeText = true,
  stretchToFill = true,
  theme = "primary",
  ...props
}) => {
  const buttonClasses = classNames("cp-button", className, {
    [`cp-button--${theme}`]: !hollow,
    [`cp-button--hollow-${theme}`]: hollow,
    "cp-button--stretch-to-fill": stretchToFill,
    "cp-button--loading": loading,
    "cp-button--animate-icon": animateIconOnHover,
    "cp-button--normalize-text": normalizeText,
  });

  const Component = component || HTMLButton;

  return (
    <Component className={buttonClasses} {...props}>
      <div className="cp-button__content">
        <span className="cp-button__children">{children}</span>
      </div>
    </Component>
  );
};

Button.propTypes = {
  animateIconOnHover: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.any,
  hollow: PropTypes.bool,
  normalizeText: PropTypes.bool,
  loading: PropTypes.bool,
  stretchToFill: PropTypes.bool,
  theme: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};

export default Button;
