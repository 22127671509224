// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compensation-survey-js": () => import("./../../../src/pages/compensation-survey.js" /* webpackChunkName: "component---src-pages-compensation-survey-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-and-affiliates-js": () => import("./../../../src/pages/locations-and-affiliates.js" /* webpackChunkName: "component---src-pages-locations-and-affiliates-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-philanthropy-js": () => import("./../../../src/pages/philanthropy.js" /* webpackChunkName: "component---src-pages-philanthropy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-survey-results-2023-js": () => import("./../../../src/pages/survey-results/2023.js" /* webpackChunkName: "component---src-pages-survey-results-2023-js" */),
  "component---src-pages-survey-results-2024-js": () => import("./../../../src/pages/survey-results/2024.js" /* webpackChunkName: "component---src-pages-survey-results-2024-js" */),
  "component---src-pages-survey-results-index-js": () => import("./../../../src/pages/survey-results/index.js" /* webpackChunkName: "component---src-pages-survey-results-index-js" */)
}

